@font-face {
  font-family: 'Mini Pizza';
  src: url(./fonts/Mini\ Pizza.ttf);
}

body {
  background: linear-gradient(
    -45deg,
    #ff6ad5,
    #c774e8,
    #ad8cff,
    #8795e8,
    #94d0ff,
    #10d7ae
  );
  background-size: 400% 400%;
  animation: gradients 60s ease infinite;
  height: 100vh;
}

.App {
  display: grid;
  place-items: center;
  min-height: 100vh;
}

.App > main {
  color: white;
  border-radius: 10px;
  background-color: hsla(0, 0%, 0%, 0.75);
  position: relative;
  margin: 0 auto;
  margin-bottom: 10rem;
  padding: 4em 3em;
  max-width: 100%;
  overflow: hidden;
  border: 2px solid black;
  font-size: 2em;
  text-align: center;
}

@keyframes gradients {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes upDown {
  to {
    transform: translatey(-0.25em);
  }
}

main h1 {
  display: inline-block;
  margin-bottom: 1rem;
  font-family: 'Mini Pizza';
}

main h1 b {
  display: inline-block;
  font-size: 1.25em;
  --outline: black;
  text-shadow: 2px 2px var(--outline), -2px 2px var(--outline),
    2px -2px var(--outline), -2px -2px var(--outline);
  animation: upDown 0.5s alternate infinite ease-in-out;
}

main h1 b:nth-of-type(1) {
  animation-delay: 0s;
  --outline: hsl(0, 100%, 50%);
}
main h1 b:nth-of-type(2) {
  animation-delay: 0.1s;
  --outline: hsl(40, 100%, 50%);
}
main h1 b:nth-of-type(3) {
  animation-delay: 0.2s;
  --outline: hsl(100, 100%, 50%);
}
main h1 b:nth-of-type(4) {
  animation-delay: 0.3s;
  --outline: hsl(180, 100%, 50%);
}
main h1 b:nth-of-type(5) {
  animation-delay: 0.4s;
  --outline: hsl(200, 100%, 50%);
}
main h1 b:nth-of-type(6) {
  animation-delay: 0.5s;
  --outline: hsl(280, 100%, 50%);
}
main h1 b:nth-of-type(7) {
  animation-delay: 0.6s;
  --outline: hsl(320, 100%, 50%);
}

@keyframes scroll {
  0% {
    transform: rotate(0.5deg) translate(0, 0rem);
  }
  50% {
    transform: rotate(0.25deg) translate(0, 1rem);
  }
  100% {
    transform: rotate(0.5deg) translate(0, 2rem);
  }
}

/* TV static is cool lol */
main::before {
  content: '';
  display: block;
  position: absolute;
  top: -3rem;
  left: -1.5rem;
  z-index: 1;
  width: calc(100% + 3rem);
  height: calc(100% + 6rem);
  background-image: linear-gradient(
      0deg,
      rgba(0, 255, 255, 0.1) 0%,
      rgba(0, 255, 255, 0.1) 25%,
      rgba(0, 255, 255, 0.05) 50%,
      rgba(0, 255, 255, 0.05) 90%,
      rgba(0, 255, 255, 0.1) 100%
    ),
    linear-gradient(
      0deg,
      rgba(255, 0, 0, 0.1) 0%,
      rgba(255, 0, 0, 0.1) 15%,
      rgba(255, 0, 0, 0.05) 40%,
      rgba(255, 0, 0, 0.05) 90%,
      rgba(255, 0, 0, 0.1) 100%
    );
  background-size: 100% 1rem;

  pointer-events: none;

  animation-name: scroll;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

a {
  color: inherit !important;
}
