.title-bar {
  background: linear-gradient(
    90deg,
    var(--dialog-blue),
    var(--dialog-blue-light)
  );
}

.vertical-bar,
button,
input[type='reset'],
input[type='submit'] {
  background: var(--surface);
  box-shadow: inset -1px -1px var(--window-frame),
    inset 1px 1px var(--button-face), inset -2px -2px var(--button-shadow),
    inset 2px 2px var(--button-highlight);
}

button:not(:disabled):active,
input[type='reset']:not(:disabled):active,
input[type='submit']:not(:disabled):active {
  box-shadow: inset -1px -1px var(--button-highlight),
    inset 1px 1px var(--window-frame), inset -2px -2px var(--button-face),
    inset 2px 2px var(--button-shadow);
  text-shadow: 1px 1px #222;
}

.window.maple {
  /* Color */
  --text-color: #222222;
  --surface: #e6d8ae;
  --button-highlight: #f2ecd7;
  --button-face: #e6d8ae;
  --button-shadow: #c6a646;
  --window-frame: #0a0a0a;
  --dialog-blue: #800000;
  --dialog-blue-light: #be9936;
  --dialog-gray: #be9936;
  --dialog-gray-light: #c6a646;
  --link-blue: #0000ff;
  background: var(--surface);
  box-shadow: inset -1px -1px var(--window-frame),
    inset 1px 1px var(--button-face), inset -2px -2px var(--button-shadow),
    inset 2px 2px var(--button-highlight);
}

.window.lilac {
  /* Color */
  --text-color: #222222;
  --surface: #aea8d9;
  --button-highlight: #ffffff;
  --button-face: #dfdfdf;
  --button-shadow: #808080;
  --window-frame: #0a0a0a;
  --dialog-blue: #800080;
  --dialog-blue-light: #3989af;
  --dialog-gray: #808080;
  --dialog-gray-light: #b5b5b5;
  --link-blue: #0000ff;
  background: var(--surface);
  /* TODO */
  box-shadow: inset -1px -1px var(--window-frame),
    inset 1px 1px var(--button-face), inset -2px -2px var(--button-shadow),
    inset 2px 2px var(--button-highlight);
}

.window.rose {
  /* Color */
  --text-color: #222222;
  --surface: #cfafb7;
  --button-highlight: #ffffff;
  --button-face: #dfdfdf;
  --button-shadow: #808080;
  --window-frame: #0a0a0a;
  --dialog-blue: #484060;
  --dialog-blue-light: #9e82b6;
  --dialog-gray: #808080;
  --dialog-gray-light: #b5b5b5;
  --link-blue: #0000ff;
  background: var(--surface);
  /* TODO */
  box-shadow: inset -1px -1px var(--window-frame),
    inset 1px 1px var(--button-face), inset -2px -2px var(--button-shadow),
    inset 2px 2px var(--button-highlight);
}
